import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';

import Image, { ImageData } from '../Image';
import { Sizes } from '../../constants/Image';

interface Props {
  images?: ImageData[];
  productSlug?: string;
  productNew?: boolean;
  prices?: [];
}

@observer
export default class Images extends React.Component<Props> {
  @observable currentProductSlug = '';
  @observable activeImage = null;

  constructor(props: Props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate() {
    this.update();
  }

  update() {
    const { images, productSlug } = this.props;

    if (productSlug === this.currentProductSlug) {
      return;
    }
    this.currentProductSlug = productSlug;

    const cover = images ? images[0] : undefined;

    this.setActiveImage(cover);
  }

  @action setActiveImage(image) {
    this.activeImage = image;
  }

  public render() {
    const { images } = this.props;

    const thumbs = images.map((image) => (
      <div
        key={image.id}
        className="cursor-pointer grow-0 shrink-0 border p-1 border-solid border-transparent"
        onClick={this.setActiveImage.bind(this, image)}
      >
        <Image
          image={image}
          key={image.id}
          defaultSize={Sizes.W100}
          allSizes={[Sizes.W100, Sizes.W540]}
          className={`${this.activeImage && image.id === this.activeImage.id ? 'border border-solid border-rgrey-light' : ''} rounded bg-rgrey-lightlight object-cover w-28 h-auto `}
          width="100"
          height="100"
        />
      </div>
    ));

    return (
      <div
        className={`flex flex-col-reverse grow shrink relative xl:flex-row md:justify-end`}
      >
        <div className="flex flex-row flex-wrap -mx-1 my-2.5 md:mt-6 md:grow-0 md:shrink-0 xl:flex-col xl:-mt-1 xl:-ml-1 xl:mr-6 xl:mb-0">
          {thumbs}
        </div>
        <div className="bg-repeat-round flex flex-auto relative justify-start">
          <div className="rounded mx-auto my-0">
            <Image
              className={
                'rounded bg-rgrey-lightlight object-cover w-[560px] md:w-[800px] h-auto'
              }
              defaultSize={Sizes.W800}
              image={this.activeImage}
              allSizes={[Sizes.W540, Sizes.W800, Sizes.W1600]}
            />
          </div>
        </div>
      </div>
    );
  }
}
